import React from 'react';

import './ProfileEditableField.scss';

interface Props {
  value: string;
}

export const ProfileEditableField: React.FC<Props> = (props) => {
  const {value} = props;

  return (
    <div className="pe-field">
      <div className="pe-field__value">{value}</div>
      {/*<button className="pe-field__btn" type="button">Change</button>*/}
    </div>
  );
};
