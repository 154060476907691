import * as React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { ROUTES } from './configs/routes';
import {
  auth,
  isOnboardComplete,
  isOnboardIncomplete,
  notAuth,
} from './services/auth';
import { Layout } from './components/layouts/Layout/Layout';

import { SignUpPage } from './pages/nonauth/SignUpPage/SignUpPage';
import { SignInPage } from './pages/nonauth/SignInPage/SignInPage';
import { GuardianInfoPage } from './pages/auth/GuardianInfoPage/GuardianInfoPage';
import { PaymentPage } from './pages/auth/PaymentPage/PaymentPage';
import { PaymentCompletePage } from './pages/auth/PaymentCompletePage/PaymentCompletePage';
import { ProfilePage } from './pages/auth/ProfilePage/ProfilePage';
import { ProfileLayout } from './components/layouts/ProfileLayout/ProfileLayout';
import { ForgotPasswordPage } from './pages/nonauth/ForgotPasswordPage/ForgotPasswordPage';

const AppRouter = () => (
  <Router>
    <Switch>
      <Route path={ROUTES.PROFILE} exact>
        <ProfileLayout>
          <Route
            exact
            path={ROUTES.PROFILE_INFO}
            component={auth(isOnboardComplete(ProfilePage))}
          />
        </ProfileLayout>
      </Route>
      <Route>
        <Layout>
          <Route exact path={ROUTES.SIGN_IN} component={notAuth(SignInPage)} />
          <Route
            exact
            path={ROUTES.FORGOT_PASSWORD}
            component={notAuth(ForgotPasswordPage)}
          />
          <Route
            exact
            path={ROUTES.GUARDIAN}
            component={auth(GuardianInfoPage)}
          />
          <Route exact path={ROUTES.PAYMENT} component={auth(PaymentPage)} />
          <Route
            exact
            path={ROUTES.PAYMENT_COMPLETE}
            component={auth(PaymentCompletePage)}
          />
          {/* <Redirect strict exact from={ROUTES.SIGN_UP} to={ROUTES.SIGN_IN} /> TODO: Commented out due to vague redirections from other routes */}
        </Layout>
      </Route>
    </Switch>
  </Router>
);

export default AppRouter;
