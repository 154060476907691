export const URLS = {
  ROOT: '/',
  SIGN_UP: '/sign-up',
  SIGN_IN: '/sign-in',
  FORGOT_PASSWORD: '/reset-password',
  GUARDIAN: '/guardian',
  PAYMENT: '/payment',
  PROFILE: 'profile',
  INFO: 'info',
  PAYMENT_COMPLETE: '/payment-complete',
  PARAMS: {
    PATH_OPTIONAL: ':path?',
  },
};
