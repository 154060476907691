import React, { forwardRef, Ref } from 'react';
import classNames from 'classnames';
import InputMask from "react-input-mask";

import './Input.scss';

interface Props {
  value: string;
  name?: string;
  title?: string;
  placeholder?: string;
  reset?: boolean;
  completed?: boolean;
  password?: boolean;
  disabled?: boolean;
  error?: string;
  mask?: string;
  ref?: Ref<any>;
  onChange?(value: string, name?: string): void;
}

export const Input: React.FC<Props> = forwardRef((props, ref: any) => {
  const {title, value, name, placeholder, error, reset, completed, password, disabled, mask, onChange} = props;
  const showResetBtn = reset && !!value.length;
  const type = password ? 'password' : 'text';
  const wrapperClass = classNames('input', {
    'input--reset': reset,
    'input--completed': completed,
    'input--disabled': disabled,
    'input--error': !!error,
  });

  const handleChange = (e: React.ChangeEvent<any>) => {
    const input = e.target;

    onChange && onChange(input.value, name);
  };

  const handleReset = () => {
    onChange && onChange('', name);
  };

  return (
    <div className={wrapperClass}>
      {title && <div className="input__title">{title}</div>}
      {!!mask ? (
          <InputMask
            mask={mask}
            maskPlaceholder=""
            // maskChar={null}
            onChange={handleChange}
            value={value}
          >
            <input
              ref={ref}
              type={type}
              name={name}
              placeholder={placeholder}
              disabled={completed}
            />
          </InputMask>
        )
        : (
          <input
            ref={ref}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            disabled={completed}
            onChange={handleChange}
          />
        )}
      {error && <div className="input__error">{error}</div>}
      {showResetBtn && <button className="input__reset-btn" type="button" onClick={handleReset} />}
      {completed && <span className="input__check" />}
    </div>
  );
});
