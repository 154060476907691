import React from 'react';

import './QuestionAction.scss';

interface Props {
  text: string;
  actionText: string;
  onClick(): void;
}

export const QuestionAction: React.FC<Props> = (props) => {
  const {text, actionText, onClick} = props;

  return (
    <div className="quest-action">
      <div className="quest-action__text">{text}</div>
      <button type="button" className="quest-action__btn" onClick={onClick}>{actionText}</button>
    </div>
  );
};
