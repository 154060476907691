import React from 'react';

import './ProfileCard.scss';

interface Props {
  title: string;
}

export const ProfileCard: React.FC<Props> = (props) => {
  const {title, children} = props;

  return (
    <div className="profile-card">
      <div className="profile-card__head">
        <div className="profile-card__title">{title}</div>
      </div>
      <div className="profile-card__body">{children}</div>
    </div>
  );
};
