import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { API_ENDPOINTS, API_URL } from '../configs/api';
import { Http } from '../services/http';
import { GuardianEntity, SignUpEntity } from '../types/authorization';
import { ROUTES } from '../configs/routes';
import {
  actionSetUserIdPhoto,
  actionSetUserProfile,
  actionSetUserProfilePhoto,
  actionUserQrCode
} from '../store/actions/user-actions';
import { useUserSession } from './auth-hooks';
import { useState } from 'react';

export function useUserDataLoad() {
  const dispatch = useDispatch();

  const loadUserData = async () => {
    try {
      const url = `${API_URL}/${API_ENDPOINTS.USER_DATA}`;
      const res = await Http.get(url);

      dispatch(actionSetUserProfile(res));
    } catch (e) {
      console.log(e);
    }
  };

  const loadQr = async () => {
    try {
      const url = `${API_URL}/${API_ENDPOINTS.USER_QR}`;
      const res = await Http.get(url);

      dispatch(actionUserQrCode(res));
    } catch (e) {
      console.log(e);
    }
  };

  const loadProfilePhoto = async () => {
    try {
      const url = `${API_URL}/${API_ENDPOINTS.USER_PROFILE_PHOTO}`;
      const res = await Http.get(url);

      dispatch(actionSetUserProfilePhoto(res));
    } catch (e) {
      console.log(e);
    }
  };

  const loadIdPhoto = async () => {
    try {
      const url = `${API_URL}/${API_ENDPOINTS.USER_ID_PHOTO}`;
      const res = await Http.get(url);

      dispatch(actionSetUserIdPhoto(res));
    } catch (e) {
      console.log(e);
    }
  };

  return {loadUserData, loadQr, loadProfilePhoto, loadIdPhoto};
}

export function useUserDataUpdate() {
  const {push} = useHistory();
  const {setUserLoginStatus, saveRegistrationStatus} = useUserSession();
  const [isLoading, setIsLoading] = useState(false);

  const updateInfoData = async (data: SignUpEntity) => {
    try {
      const {address1, address2, zipCode, country, city} = data;
      const url = `${API_URL}/${API_ENDPOINTS.USER_ACCOUNT_INFO}`;
      const body = {
        city,
        address1,
        address2,
        country,
        postcode: zipCode
      };
      const res = await Http.post(url, body);

      saveRegistrationStatus(res);
      return true;
    } catch (e) {
      alert(e.message);
      return false;
    }
  };

  const updateBaseData = async (data: SignUpEntity) => {
    try {
      const {firstName, lastName, password, dob} = data;
      const url = `${API_URL}/${API_ENDPOINTS.USER_ACCOUNT_BASE}`;
      const body = {
        name: `${firstName || ''} ${lastName || ''}`,
        first_name: firstName,
        last_name: lastName,
        password,
        dob,
      };
      const res = await Http.post(url, body);

      saveRegistrationStatus(res);
      return true;
    } catch (e) {
      alert(e.message);
      return false;
    }
  };

  const saveUserData = async (data: SignUpEntity) => {
    setIsLoading(true);
    const baseDataSaved = await updateBaseData(data);
    const infoDataSaved = await updateInfoData(data);

    setIsLoading(false);

    if (baseDataSaved && infoDataSaved) {
      const yearsDiff = moment().diff(moment(data.dob), 'years');
      const userIsUnder16 = yearsDiff < 16;
      const nextRoute = userIsUnder16 ? ROUTES.GUARDIAN : ROUTES.PAYMENT;

      setUserLoginStatus(true);
      push(nextRoute);
    }
  };

  return {saveUserData, isLoading};
}

export function useUserGuardianDataUpdate() {
  const {push} = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const updateGuardianData = async (data: GuardianEntity) => {
    try {
      setIsLoading(true);
      const {address1, address2, zipCode, country, city, name, dob} = data;
      const url = `${API_URL}/${API_ENDPOINTS.GUARDIAN_INFO}`;
      const body = {
        city,
        address1,
        address2,
        country,
        name,
        dob,
        postcode: zipCode
      };
      await Http.post(url, body);

      setIsLoading(false);
      push(ROUTES.PAYMENT);
    } catch (e) {
      setIsLoading(false);
      alert(e.message);
    }
  };

  return {updateGuardianData, isLoading};
}