import React from 'react';

import './Icon.scss';

interface Props {
  name: string;
  width?: number;
  height?: number;
}

export const ICON_NAMES = {
  TICKET: 'ticket',
  INVOICE: 'invoice',
};

export const Icon: React.FC<Props> = (props) => {
  const {name, width = 20, height = 20} = props;
  const className = `icon icon--${name}`;
  const style = {
    width,
    height,
  };

  return (
    <i style={style} className={className} />
  );
};
