import { CustomAction } from '../index';
import { COMMON_ACTION_TYPES } from '../actions-types';

export interface CommonState {
  voucherIsApplied: boolean;
  appLinkIsSent: boolean;
}

const defaultState: CommonState = {
  voucherIsApplied: false,
  appLinkIsSent: false,
};

export default function commonReducer(state = defaultState, action: CustomAction) {
  switch (action.type) {
    case COMMON_ACTION_TYPES.SET_VOUCHER_CODE: {
      return {
        ...state,
        voucherIsApplied: action.data,
      };
    }
    case COMMON_ACTION_TYPES.SET_APP_LINK_SENT: {
      return {
        ...state,
        appLinkIsSent: action.data
      }
    }
    default: return state;
  }
}
