import * as React from 'react';
import { Provider } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import Router from './Router';
import { store } from './store';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_KEY } from './configs/api';

import './assets/styles/main.scss';

const stripePromise = loadStripe(STRIPE_KEY);

export default function App() {
  return (
    <Elements stripe={stripePromise} options={{locale: 'en'}}>
      <Provider store={store}>
        <Router />
      </Provider>
    </Elements>
  );
}
