import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { ROUTES } from '../configs/routes';
import { UtilsUser } from '../utils/user';

const locationHelper = locationHelperBuilder({});

export const auth = connectedRouterRedirect({
  allowRedirectBack: true,
  authenticatedSelector: () => UtilsUser.isUserLoggedIn(),
  redirectPath: (state, ownProps) => {
    return locationHelper.getRedirectQueryParam(ownProps) || ROUTES.SIGN_IN;
  },
  wrapperDisplayName: 'UserIsAuthenticated',
});

export const notAuth = connectedRouterRedirect({
  allowRedirectBack: false,
  authenticatedSelector: () => !UtilsUser.isUserLoggedIn(),
  redirectPath: (state, ownProps) => {
    return locationHelper.getRedirectQueryParam(ownProps) || ROUTES.PROFILE_INFO;
  },
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

export const isOnboardComplete = connectedRouterRedirect({
  allowedRedirectBack: false,
  authenticatedSelector: () => UtilsUser.isUserOnboardingComplete(),
  redirectPath: (state, ownProps) => {
    return locationHelper.getRedirectQueryParam(ownProps) || ROUTES.PAYMENT;
  },
  wrapperDisplayName: 'UserIsOnboarded'
})

export const isOnboardIncomplete = connectedRouterRedirect({
  allowedRedirectBack: false,
  authenticatedSelector: () => !UtilsUser.isUserOnboardingComplete(),
  redirectPath: (state, ownProps) => {
    return locationHelper.getRedirectQueryParam(ownProps) || ROUTES.PROFILE_INFO;
  },
  wrapperDisplayName: 'UserIsNotOnboarded'
})
