import React from 'react';
import classNames from 'classnames';

import './Checkbox.scss';

interface Props {
  checked: boolean;
  name?: string;
  minified?: boolean;
  disabled?: boolean;
  error?: boolean;
  text: React.ReactNode;
  onChange?(value: boolean, name: string): void;
}

export const Checkbox: React.FC<Props> = (props) => {
  const {checked, text, name, minified, disabled, error, onChange} = props;
  const wrapperClass = classNames('checkbox', {
    'checkbox--minified': minified,
    'checkbox--disabled': disabled,
    'checkbox--error': error,
  });

  const handleChange = (e: React.ChangeEvent<any>) => {
    const input = e.target;

    onChange && onChange(input.checked, name);
  };

  return (
    <label className={wrapperClass}>
      <input type="checkbox" checked={checked} onChange={handleChange} />
      <div className="checkbox__mark" />
      <div className="checkbox__text">{text}</div>
    </label>
  );
};
