import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import './ProfileMenuMainItem.scss';

interface Props {
  title: string;
  url: string;
}

export const ProfileMenuMainItem: React.FC<Props> = (props) => {
  const {title, url} = props;
  const location = useLocation();
  const isActive = location.pathname === url;

  return (
    <div className="pmm-item">
      <Link to={url}>{title}</Link>
      {isActive && <span className="pmm-item__arrow" />}
    </div>
  );
};
