import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import userReducer, { UserState } from './reducers/user-reducer';
import commonReducer, { CommonState } from './reducers/common-reducer';

export interface CustomAction {
  type: string;
  data?: any;
}

export interface ApplicationState {
  user: UserState;
  common: CommonState;
}

const reducer = combineReducers({
  user: userReducer,
  common: commonReducer,
});

export const store = createStore(reducer, composeWithDevTools());
