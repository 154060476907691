import React, { useState } from 'react';
import {
  ForgotPasswordEntity,
  ResetPasswordEntity,
} from '../../../types/authorization';
import { forgotPasswordDefaults } from '../../../data/defaults';
import { Form } from '../../_common/Form/Form';
import { Input } from '../../_common/_controls/Input/Input';
import { Button } from '../../_common/_controls/Button/Button';

import './ForgotPasswordForm.scss';
import { PasswordValidator } from '../PasswordValidator/PasswordValidator';
import { UtilsValidation } from '../../../utils/validation';

interface Props {
  onEmailSubmit(email: string): void;
  onPasswordSubmit(data: ResetPasswordEntity): void;
  token: string;
}

export const ForgotPasswordForm: React.FC<Props> = (props) => {
  const { onEmailSubmit, onPasswordSubmit, token } = props;
  const [formData, setFormData] = useState<ForgotPasswordEntity>(
    forgotPasswordDefaults
  );
  const [passwordData, setPasswordData] = useState({
    password: '',
    passwordRepeat: '',
  });
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const { email } = formData;
  const { password, passwordRepeat } = passwordData;
  const actionText = token ? 'Reset password' : 'Submit email';
  const actionIsDisabled = token && !passwordIsValid;

  const handleFieldChange = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const handlePasswordChange = (value: string, name: string) => {
    setPasswordData({ ...passwordData, [name]: value });
  };

  const handlePasswordValidationChange = (state: boolean) => {
    setPasswordIsValid(state);
  };

  const handleFormSubmit = () => {
    if (token) {
      password === passwordRepeat
        ? onPasswordSubmit({ token, password })
        : alert('Repeat password value is not the same');
    } else {
      UtilsValidation.isEmailValid(email)
        ? onEmailSubmit(email)
        : alert('Email is invalid');
    }
  };

  return (
    <Form className="fp-form" onSubmit={handleFormSubmit}>
      {!token ? (
        <Input
          title="Email"
          placeholder="Enter your email"
          value={email}
          name="email"
          onChange={handleFieldChange}
        />
      ) : (
        <>
          <PasswordValidator
            value={password}
            onChange={handlePasswordValidationChange}
          />
          <Input
            title="New Password"
            placeholder="Enter new password"
            value={password}
            name="password"
            password
            onChange={handlePasswordChange}
          />
          <Input
            title="Password Repeat"
            placeholder="Repeat new password"
            value={passwordRepeat}
            name="passwordRepeat"
            password
            onChange={handlePasswordChange}
          />
        </>
      )}
      <Button
        disabled={actionIsDisabled}
        title={actionText}
        htmlType="submit"
      />
    </Form>
  );
};
