import {
  ForgotPasswordEntity,
  GuardianEntity,
  SignInEntity,
  SignUpEntity,
  VerificationEntity
} from '../types/authorization';
import { PaymentEntity } from '../types/payment';
import { User } from '../types/user';
import { ImageEntity } from '../types/common';

export const userDefaults: User = {
  name: '',
  id: '',
  guardian_info_id: 0,
  dob: '',
  phone: '',
  address1: '',
  address2: '',
  city: '',
  country: '',
  createdAt: '',
  updatedAt: '',
  verified_at: '',
  registration_status: '',
  email: '',
  postcode: '',
};


export const verificationDefaults: VerificationEntity = {
  email: '',
  phone: '',
};

export const signUpDefaults: SignUpEntity = {
  firstName: '',
  lastName: '',
  dob: null,
  address1: '',
  address2: '',
  city: '',
  country: 'GB',
  zipCode: '',
  password: '',
  passwordRepeat: '',
  termsAccepted: false,
  updatedAccepted: false,
};

export const signInDefaults: SignInEntity = {
  login: '',
  password: '',
};

export const guardianDefaults: GuardianEntity = {
  name: '',
  dob: new Date(),
  address1: '',
  address2: '',
  city: '',
  country: '',
  zipCode: '',
  rememberInfo: false,
};

export const paymentDefaults: PaymentEntity = {
  cardNumber: '',
  expiryDate: '',
  cvcCode: '',
};

export const imageDefaults: ImageEntity = {
  filename: '',
  image: '',
  type: '',
};

export const forgotPasswordDefaults: ForgotPasswordEntity = {
  email: '',
};
