import React from 'react';

import './PlanInfo.scss';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { CommonState } from '../../store/reducers/common-reducer';

export const PlanInfo: React.FC = () => {
  const commonState = useSelector<ApplicationState, CommonState>(state => state.common);
  const {voucherIsApplied} = commonState;
  const price = voucherIsApplied ? 'Free' : '£9.99';

  return (
    <div className="plan-info">
      <div className="plan-info title">Your Order</div>
      <div className="plan-info__card">
        <div className="plan-info__card-head">
          <div className="plan-info__card-period">12-month Pass</div>
          {/*<div className="plan-info__card-price">{price}</div>*/}
        </div>
        <div className="plan-info__card-body">
          <ul className="plan-info__card-list">
            <li>ID Verification</li>
            <li>Secure QR Code Encryption</li>
            <li>Unlimited Secured Match Fit Pass Use for 12 Months</li>
          </ul>
        </div>
      </div>
      {/*<div className="plan-info__total">*/}
      {/*  <div className="plan-info__total-label">Total:</div>*/}
      {/*  <div className="plan-info__total-price">{price}</div>*/}
      {/*</div>*/}
    </div>
  );
};
