import React from 'react';
import classNames from 'classnames';

import './Button.scss';

interface Props {
  title: string;
  htmlType?: "button" | "submit" | "reset";
  type?: 'primary' | 'secondary'| 'empty' | 'danger';
  className?: string;
  disabled?: boolean;
  onClick?(): void;
}

export const Button: React.FC<Props> = (props) => {
  const {title, htmlType = 'button', type = 'primary', className, disabled, onClick} = props;
  const wrapClass = classNames('button', {
    [`button--${type}`]: !!type,
    'button--disabled': disabled,
    [className]: !!className,
  });

  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <button className={wrapClass} type={htmlType} disabled={disabled} onClick={handleClick}>
      {title}
    </button>
  );
};
