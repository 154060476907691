import React, { useEffect } from "react";
import classNames from "classnames";
import { useLocation, useHistory } from "react-router-dom";
import { Header } from "../../Header/Header";
import { PlanInfo } from "../../PlanInfo/PlanInfo";
import { ROUTES } from "../../../configs/routes";
import { useUserDataLoad } from "../../../hooks/user-hooks";
import { UtilsUser } from "../../../utils/user";

import "./Layout.scss";

export const Layout: React.FC = (props) => {
  const { children } = props;
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { loadUserData } = useUserDataLoad();
  const paymentCompleted = pathname === ROUTES.PAYMENT_COMPLETE;
  const isSignIn =
    pathname === ROUTES.SIGN_IN || pathname === ROUTES.FORGOT_PASSWORD;
  const isUserLoggedIn = UtilsUser.isUserLoggedIn();

  const wrapClass = classNames("layout", {
    "layout--completed": paymentCompleted,
    "layout--signin": isSignIn,
  });

  useEffect(() => {
    if (isUserLoggedIn) {
      loadUserData();
    }
  }, []);

  useEffect(() => {
    if (pathname === ROUTES.ROOT) {
      const nextRoute = isUserLoggedIn ? ROUTES.PROFILE_INFO : ROUTES.SIGN_IN;
      push(nextRoute);
    }
  }, [pathname, isUserLoggedIn]);

  return (
    <div className={wrapClass}>
      <Header />
      <div className="container">
        <div className="layout__row">
          <div className="layout__content">{children}</div>
          <div className="layout__sidebar">
            {!paymentCompleted && <PlanInfo />}
          </div>
        </div>
      </div>
    </div>
  );
};
