export class UtilsNavigation {
  public static buildRoute(...args: string[]): string {
    const routes = [...args];

    return `/${routes.join('/')}`;
  };

  public static getUrlGetParamByName(name: string): string | null {
    const url = new URL(window.location.href);

    return url.searchParams.get(name);
  }
}