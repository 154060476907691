import { CustomAction } from '../index';
import { USER_ACTION_TYPES } from '../actions-types';
import { User } from '../../types/user';
import { imageDefaults, userDefaults } from '../../data/defaults';
import { ImageEntity } from '../../types/common';

export interface UserState {
  userData: User;
  qrCode: ImageEntity;
  profilePhoto: ImageEntity;
  idPhoto: ImageEntity;
}

const defaultState: UserState = {
  userData: userDefaults,
  qrCode: imageDefaults,
  profilePhoto: imageDefaults,
  idPhoto: imageDefaults,
};

export default function userReducer(state = defaultState, action: CustomAction) {
  switch (action.type) {
    case USER_ACTION_TYPES.SET_USER: {
      return {
        ...state,
        userData: action.data,
      };
    }
    case USER_ACTION_TYPES.SET_USER_QR_CODE: {
      return {
        ...state,
        qrCode: action.data,
      };
    }
    case USER_ACTION_TYPES.SET_USER_PROFILE_PHOTO: {
      return {
        ...state,
        profilePhoto: action.data,
      };
    }
    case USER_ACTION_TYPES.SET_USER_ID_PHOTO: {
      return {
        ...state,
        idPhoto: action.data,
      };
    }
    case USER_ACTION_TYPES.RESET_USER: {
      return {
        userData: userDefaults,
        qrCode: imageDefaults,
        profilePhoto: imageDefaults,
        idPhoto: imageDefaults,
      };
    }
    default: return state;
  }
}
