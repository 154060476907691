import React from 'react';
import { FormCard } from '../../../components/_common/FormCard/FormCard';
import { PaymentPlaceholder } from '../../../components/_common/PaymentPlaceholder/PaymentPlaceholder';
import { QuestionAction } from '../../../components/_common/QuestionAction/QuestionAction';
import { CompletionCard } from '../../../components/_common/CompletionCard/CompletionCard';
import { GuardianForm } from '../../../components/authorization/GuardianForm/GuardianForm';
import { GuardianEntity } from '../../../types/authorization';
import { useUserGuardianDataUpdate } from '../../../hooks/user-hooks';

export const GuardianInfoPage: React.FC = () => {
  const {updateGuardianData, isLoading} = useUserGuardianDataUpdate();

  const handleQuestionClick = () => {
  };

  const handleFormSubmit = (data: GuardianEntity) => {
    updateGuardianData(data);
  };

  return (
    <>
      <CompletionCard
        title="Thank your for signing up!"
        text="You can continue with the payment process for subscription now."
      />
      <FormCard title="Enter Guardian’s Information">
        <QuestionAction
          text={`
            As you are below 16 years old, we need your parent or guardian’s consent to you having a Match Fit Pass.
             Please add their information below.
          `}
          actionText="Why do we require this information?"
          onClick={handleQuestionClick}
        />
        <GuardianForm isLoading={isLoading} onSubmit={handleFormSubmit} />
      </FormCard>
      <PaymentPlaceholder />
    </>
  );
};
