import { USER_TOKEN_NAMESPACE } from '../constants/namespaces';
import { LocalStorage } from './storage';

export class Http {
  public static getHeaders = async () => {
    const userToken = await LocalStorage.get(USER_TOKEN_NAMESPACE);

    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    };
  };

  public static get = async (url: string) => {
    try {
      const headers = await Http.getHeaders();
      const response = await fetch(url, {
        method: 'GET',
        headers,
      });
      const responseData = await response.json();

      if (!response.ok) {
        return Promise.reject(responseData);
      }

      return responseData;
    } catch (e) {
      console.log(e);
    }
  };

  public static post = async (url: string, body?: any) => {
    try {
      const headers = await Http.getHeaders();
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers,
      });
      const responseData = await response.json();

      if (!response.ok) {
        return Promise.reject(responseData);
      }

      return responseData;
    } catch (e) {
      console.log(e);
    }
  };
}