import React, { useState } from 'react';
import classNames from 'classnames';
import { Modal } from '../../_common/Modal/Modal';

import './ProfilePhotoInfo.scss';
import { Svg, SVG_ICONS } from '../../_common/Svg/Svg';

interface Props {
  imageUrl: string;
  placeholder: string;
  status?: string;
  notice?: string;
  verified?: boolean;
}

export const ProfilePhotoInfo: React.FC<Props> = (props) => {
  const {imageUrl, status, notice, verified, placeholder} = props;
  const [showFullSize, setShowFullSize] = useState(false);
  const statusClass = classNames('pp-info__status', {
    'pp-info__status--verified': verified,
  });
  const isLoaded = !!imageUrl;

  const toggleFullSize = () => {
    setShowFullSize(!showFullSize);
  };

  return (
    <>
      <div className="pp-info">
        {isLoaded
          ? (
            <button type="button" className="pp-info__image-wrap" onClick={toggleFullSize}>
          <span className="pp-info__zoom-ic">
            <Svg icon={SVG_ICONS.ZOOM_IN} size={20} />
          </span>
              <img src={imageUrl} alt="" />
            </button>
          )
          : (
            <div>{placeholder}</div>
          )}
        {status && isLoaded && <div className={statusClass}>{status}</div>}
        {notice && isLoaded && <div className="pp-info__notice">{notice}</div>}
      </div>
      <Modal isOpen={showFullSize} onClose={toggleFullSize}>
        <div className="pp-info-full">
          <img src={imageUrl} alt="" />
        </div>
      </Modal>
    </>
  );
};
