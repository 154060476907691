import React, { useEffect } from 'react';
import { Header } from '../../Header/Header';
import { ProfileMenu } from '../../profile/ProfileMenu/ProfileMenu';
import { useUserDataLoad } from '../../../hooks/user-hooks';

import './ProfileLayout.scss';

export const ProfileLayout: React.FC = (props) => {
  const {children} = props;
  const {loadUserData} = useUserDataLoad();

  useEffect(() => {
    loadUserData();
  }, []);

  return (
    <div className="profile-layout">
      <Header isProfile />
      <div className="container">
        <div className="profile-layout__row">
          <div className="profile-layout__sidebar">
            <ProfileMenu />
          </div>
          <div className="profile-layout__content">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
