import * as AWS from 'aws-sdk';
import { AWS_ACCESS_KEY_ID, AWS_ACCESS_KEY, AWS_REGION } from '../configs/aws';
import { STRIPE_KEY } from '../configs/api';

AWS.config.update({
  accessKeyId: AWS_ACCESS_KEY_ID,
  secretAccessKey: AWS_ACCESS_KEY,
  region: AWS_REGION
});

export class Sms {
  private static sns = new AWS.SNS();
  private static SENDER_ID = 'MFP';

  public static sendSms (PhoneNumber: string, Message: string) {
    return this.sns
      .publish({
        PhoneNumber,
        Message,
        MessageAttributes: {
          'AWS.SNS.SMS.SenderID': {
              'DataType': 'String',
              'StringValue': this.SENDER_ID,
          }
        }
      })
      .promise()
      .then(() => console.log(`SMS sent for phone='${PhoneNumber}'`))
      .catch(err => console.log(`SMS sending failed for phone='${PhoneNumber}'`, err));
  }
}