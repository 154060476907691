import { URLS } from '../constants/urls';
import { UtilsNavigation } from '../utils/navigation';

export const ROUTES = {
  ROOT: URLS.ROOT,
  SIGN_UP: URLS.SIGN_UP,
  SIGN_IN: URLS.SIGN_IN,
  FORGOT_PASSWORD: URLS.FORGOT_PASSWORD,
  GUARDIAN: URLS.GUARDIAN,
  PAYMENT: URLS.PAYMENT,
  PROFILE: UtilsNavigation.buildRoute(URLS.PROFILE, URLS.PARAMS.PATH_OPTIONAL),
  PROFILE_INFO: UtilsNavigation.buildRoute(URLS.PROFILE, URLS.INFO),
  PAYMENT_COMPLETE: URLS.PAYMENT_COMPLETE,
};
