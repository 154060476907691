import React from 'react';
import whiteLogo from '../../../assets/images/svg/logo-white.svg';
import { FormCard } from '../../../components/_common/FormCard/FormCard';
import { ForgotPasswordForm } from '../../../components/authorization/ForgotPasswordForm/ForgotPasswordForm';
import { useForgotPassword } from '../../../hooks/auth-hooks';
import { ResetPasswordEntity } from '../../../types/authorization';
import { UtilsNavigation } from '../../../utils/navigation';

export const ForgotPasswordPage: React.FC = () => {
  const { sendEmail, resetPassword } = useForgotPassword();

  const token = UtilsNavigation.getUrlGetParamByName('token');

  const handleEmailSend = (email: string) => {
    sendEmail(email);
  };

  const handlePasswordReset = (data: ResetPasswordEntity) => {
    resetPassword(data);
  };

  return (
    <div className="signin">
      <div className="signin__content">
        <div className="signin__logo">
          <img src={whiteLogo} alt="" />
        </div>
        <FormCard title="Forgot password">
          <ForgotPasswordForm
            token={token}
            onPasswordSubmit={handlePasswordReset}
            onEmailSubmit={handleEmailSend}
          />
        </FormCard>
      </div>
    </div>
  );
};
