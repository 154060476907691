import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Sms } from '../services/sms';
import { actionSetAppLinkState } from '../store/actions/common-actions';
import { APPLE_DOWNLOAD_LINK, ANDROID_DOWNLOAD_LINK } from '../configs/common';

export function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = event => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },

    [ref, handler]
  );
}

export function useSms() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const sendSmsAppLinks = async (phone: string) => {
    const message = `Download the Match Fit Pass App at Apple: ${APPLE_DOWNLOAD_LINK} or Android: ${ANDROID_DOWNLOAD_LINK}`;
    
    try {
      setIsLoading(true)
      
      dispatch(actionSetAppLinkState(false));
      await Sms.sendSms(phone, message)
      dispatch(actionSetAppLinkState(true));
      setIsLoading(false);

    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  }
  
  return { sendSmsAppLinks, isLoading };
}