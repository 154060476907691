import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Http } from '../services/http';
import { useHistory } from 'react-router-dom';
import { API_ENDPOINTS, API_URL } from '../configs/api';
import { LocalStorage } from '../services/storage';
import { USER_LOGIN_STATUS_NAMESPACE, USER_REGISTRATION_STATUS_NAMESPACE, USER_TOKEN_NAMESPACE } from '../constants/namespaces';
import { User } from '../types/user';
import { actionResetUser, actionSetUserProfile } from '../store/actions/user-actions';
import { ApplicationState } from '../store';
import { UserState } from '../store/reducers/user-reducer';
import { AuthResponse, ResetPasswordEntity, SignInEntity } from '../types/authorization';
import { ROUTES } from '../configs/routes';

export function useUserSession() {  
  const saveRegistrationStatus = (responseData) => {
    if (responseData.registration_status || (responseData.user && responseData.user.registration_status)) {
      LocalStorage.set(USER_REGISTRATION_STATUS_NAMESPACE, responseData.registration_status || responseData.user.registration_status);
    }
  };

  const saveToken = (data: AuthResponse) => {
    saveRegistrationStatus(data);
    LocalStorage.set(USER_TOKEN_NAMESPACE, data.accessToken);
  };

  const setUserLoginStatus = (isLoggedIn: boolean) => {
    if (isLoggedIn) {
      LocalStorage.set(USER_LOGIN_STATUS_NAMESPACE, 'true');
    } else {
      LocalStorage.delete(USER_LOGIN_STATUS_NAMESPACE);      
    }
  };

  return {saveRegistrationStatus, saveToken, setUserLoginStatus};
}

export function usePhoneVerification() {
  const dispatch = useDispatch();
  const {saveToken, saveRegistrationStatus} = useUserSession();
  const [isLoading, setIsLoading] = useState(false);

  const sendPhoneCode = async (phone: string) => {
    try {
      setIsLoading(true);
      const url = `${API_URL}/${API_ENDPOINTS.SEND_PHONE_CODE}`;
      const body = {phone};
      const res = await Http.post(url, body);

      saveToken(res);

      dispatch(actionSetUserProfile(res.user));
      setIsLoading(false);
      return true;
    } catch (e) {
      setIsLoading(false);
      alert(e.message);
      return false;
    }
  };

  const verifyPhoneCode = async (code: string) => {
    try {
      setIsLoading(true);
      const url = `${API_URL}/${API_ENDPOINTS.VERIFY_PHONE_CODE}`;
      const body = {code};
      const res = await Http.post(url, body) as User;

      saveRegistrationStatus(res);
      dispatch(actionSetUserProfile(res));
      setIsLoading(false);
      return true;
    } catch (e) {
      setIsLoading(false);
      alert(e.message);
      return false;
    }
  };

  const resendPhoneCode = async () => {
    try {
      setIsLoading(true);
      const url = `${API_URL}/${API_ENDPOINTS.VERIFY_PHONE_CODE_RESEND}`;
      const body = {};
      await Http.post(url, body) as User;
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      alert(e.message);
      // return false;
    }
  };

  return {sendPhoneCode, verifyPhoneCode, resendPhoneCode, isLoading};
}

export function useEmailVerification() {
  const dispatch = useDispatch();
  const userState = useSelector<ApplicationState, UserState>((state) => state.user);
  const {userData} = userState;
  const [isLoading, setIsLoading] = useState(false);

  const sendEmailCode = async (email: string) => {
    try {
      setIsLoading(true);
      const url = `${API_URL}/${API_ENDPOINTS.SEND_EMAIL_CODE}`;
      const body = {email};
      const res = await Http.post(url, body);

      dispatch(actionSetUserProfile({...userData, email}));
      setIsLoading(false);
      return true;
    } catch (e) {
      setIsLoading(false);
      if (e.context) {
        alert(e.context.reason);
      } else {
        alert(e.message);
      }

      return false;
    }
  };

  const verifyEmailCode = async (token: string) => {
    try {
      setIsLoading(true);
      const url = `${API_URL}/${API_ENDPOINTS.VERIFY_EMAIL_CODE}`;
      const body = {token};
      const res = await Http.post(url, body);

      setIsLoading(false);
      return true;
    } catch (e) {
      setIsLoading(false);
      alert(e.message);
      return false;
    }
  };

  const resendEmailCode = async () => {
    try {
      setIsLoading(true);
      const url = `${API_URL}/${API_ENDPOINTS.SEND_EMAIL_CODE_RESEND}`;
      const body = {};
      await Http.post(url, body);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      alert(e.message);
      // return false;
    }
  };

  return {sendEmailCode, verifyEmailCode, resendEmailCode, isLoading};
}

export function useUserLogin() {
  const dispatch = useDispatch();
  const {saveToken, setUserLoginStatus} = useUserSession();
  const {push} = useHistory();

  const login = async (data: SignInEntity) => {
    try {
      const url = `${API_URL}/${API_ENDPOINTS.LOGIN}`;
      const res: AuthResponse = await Http.post(url, data);
      const {user} = res;

      saveToken(res);
      setUserLoginStatus(true);
      dispatch(actionSetUserProfile(user));
      push(ROUTES.PROFILE_INFO);
    } catch (e) {
      alert(e.message);
    }
  };

  return {login};
}

export function useUserLogout() {
  const {push} = useHistory();
  const dispatch = useDispatch();

  const logout = async () => {
    LocalStorage.delete(USER_TOKEN_NAMESPACE);
    LocalStorage.delete(USER_LOGIN_STATUS_NAMESPACE);
    LocalStorage.delete(USER_REGISTRATION_STATUS_NAMESPACE);
    dispatch(actionResetUser());
    push(ROUTES.SIGN_IN);
  };

  return {logout};
}

export function useForgotPassword() {
  const {push} = useHistory();

  const sendEmail = async (email: string) => {
    try {
      const requestData = {email: email.toLowerCase()};
      const url = `${API_URL}/${API_ENDPOINTS.FORGOT_PASSWORD}`;

      await Http.post(url, requestData);

      alert('Check your email');
    } catch (e) {
      alert(e.message);
    }
  };

  const resetPassword = async (data: ResetPasswordEntity) => {
    try {
      const url = `${API_URL}/${API_ENDPOINTS.RESET_PASSWORD}`;

      await Http.post(url, data);

      push(ROUTES.SIGN_IN);
    } catch (e) {
      alert(e.message);
    }
  };

  return {sendEmail, resetPassword};
}
