import React, { useState } from 'react';
import { Input } from '../../_common/_controls/Input/Input';
import { Icon } from '../../_common/Icon/Icon';
import { Form } from '../../_common/Form/Form';
import { useVoucherCode } from '../../../hooks/payment-hooks';
import { Button } from '../../_common/_controls/Button/Button';

import './VoucherField.scss';

export const VoucherField: React.FC = () => {
  const {sendCode, isLoading} = useVoucherCode();
  const [value, setValue] = useState('');

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  const handleSubmit = () => {
    sendCode(value);
  };

  return (
    <Form onSubmit={handleSubmit} className="voucher-field">
      <div className="voucher-field__wrap">
        <div className="voucher-field__title"><Icon name="tag" />Voucher</div>
        <Input value={value} placeholder="Enter your voucher code..." onChange={handleChange} />
      </div>
      <Button title="Enter" htmlType="submit" disabled={!value || isLoading} />
    </Form>
  );
};
