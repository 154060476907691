import React from 'react';
import { Link } from 'react-router-dom';
import { ProfileMenuMainItem } from './ProfileMenuMainItem/ProfileMenuMainItem';
import { ROUTES } from '../../../configs/routes';
import { Svg, SVG_ICONS } from '../../_common/Svg/Svg';
import { useUserLogout } from '../../../hooks/auth-hooks';

import './ProfileMenu.scss';

export const ProfileMenu: React.FC = () => {
  const {logout} = useUserLogout();

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="profile-menu">
      <div className="profile-menu__top">
        <div className="profile-menu__main">
          <div className="profile-menu__main-title">Account & Settings</div>
          <div className="profile-menu__main-items">
            <ProfileMenuMainItem title="Pass & Profile" url={ROUTES.PROFILE_INFO} />
            {/*<ProfileMenuMainItem title="Security" url={ROUTES.ROOT} />*/}
          </div>
        </div>
        {/*<Link className="profile-menu__item" to={ROUTES.ROOT}><Svg icon={SVG_ICONS.TICKET} size={16} />My Tickets</Link>*/}
        {/*<Link className="profile-menu__item" to={ROUTES.ROOT}><Svg icon={SVG_ICONS.INVOICE} size={16} />Billing</Link>*/}
      </div>
      <button
        type="button"
        onClick={handleLogout}
        className="profile-menu__item">
        <Svg icon={SVG_ICONS.LOGOUT} size={16} />Sign Out
      </button>
    </div>
  );
};
