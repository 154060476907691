import React from 'react';

import './ProfileHeader.scss';

interface Props {
  title: string;
}

export const ProfileHeader: React.FC<Props> = (props) => {
  const {title} = props;

  return (
    <div className="profile-header">
      <div className="profile-header__title">{title}</div>
    </div>
  );
};
