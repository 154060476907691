import { CustomAction } from '../index';
import { USER_ACTION_TYPES } from '../actions-types';
import { User } from '../../types/user';
import { ImageEntity } from '../../types/common';

export const actionSetUserProfile = (data: User): CustomAction => {
  return {
    type: USER_ACTION_TYPES.SET_USER,
    data,
  };
};

export const actionUserQrCode = (data: ImageEntity): CustomAction => {
  return {
    type: USER_ACTION_TYPES.SET_USER_QR_CODE,
    data,
  };
};

export const actionSetUserProfilePhoto = (data: ImageEntity): CustomAction => {
  return {
    type: USER_ACTION_TYPES.SET_USER_PROFILE_PHOTO,
    data,
  };
};

export const actionSetUserIdPhoto = (data: ImageEntity): CustomAction => {
  return {
    type: USER_ACTION_TYPES.SET_USER_ID_PHOTO,
    data,
  };
};

export const actionResetUser = (): CustomAction => {
  return {
    type: USER_ACTION_TYPES.RESET_USER,
  };
};
