import { CustomAction } from '../index';
import { COMMON_ACTION_TYPES } from '../actions-types';

export const actionSetVoucherState = (data: boolean): CustomAction => {
  return {
    type: COMMON_ACTION_TYPES.SET_VOUCHER_CODE,
    data,
  };
};

export const actionSetAppLinkState = (data: boolean): CustomAction => {
  return {
    type: COMMON_ACTION_TYPES.SET_APP_LINK_SENT,
    data,
  }
}