import React from 'react';
import { ReactSVG } from 'react-svg';
import { useSelector } from 'react-redux';

import { ApplicationState } from '../../../store';
import { Input } from '../../_common/_controls/Input/Input';
import { Button } from '../../_common/_controls/Button/Button';
import { Form } from '../../_common/Form/Form';
import AppleStore from '../../../assets/images/svg/app-store.svg';
import GooglePlayStore from '../../../assets/images/svg/google-play-store.svg';
import { Svg, SVG_ICONS } from '../../_common/Svg/Svg';
import { CommonState } from '../../../store/reducers/common-reducer';

import './AppDownloadCard.scss';

interface Props {
  phone: string;
  onSubmit(): void;
  isLoading: boolean;
}

export const AppDownloadCard: React.FC<Props> = (props) => {
  const commonState = useSelector<ApplicationState, CommonState>(state => state.common);
  const {phone, onSubmit, isLoading} = props;
  const { appLinkIsSent } = commonState;

  const handleSubmit = () => {
    onSubmit();
  }

  console.log(appLinkIsSent);

  return (
    <div className="apd-card">
      <div className="apd-card__title">Download Match Fit app to verify your ID:</div>
      <div className="apd-card__content">
        <div className="apd-card__col">
          <a href="https://apps.apple.com/gb/app/match-fit-pass/id1560988662">
            <ReactSVG className="apd-card__app-icon" src={AppleStore} wrapper="div" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.matchfitpass&hl=en_US&gl=US">
            <ReactSVG className="apd-card__app-icon" src={GooglePlayStore} wrapper="div" />
          </a>
        </div>
        <div className="apd-card__col">
          <div className="apd-card__section-title">Send download link to phone number:</div>
          <Form onSubmit={handleSubmit}>
            <Input value={phone} completed disabled />
            {
              !isLoading && !appLinkIsSent
                ? <Button title="Send download link" htmlType="submit"/>
                : appLinkIsSent ?
                  (
                    <>
                      <div className="apd-card__success">
                        <span><Svg icon={SVG_ICONS.CHECK_CIRCLE_GREEN} size={30} /></span>
                        <span>Download link has been sent to your phone</span>
                      </div>
                      <div className="apd-card__resend">
                        <span>Don't receive the message? <button className="apd-card__btn" type="submit">Try again.</button></span>
                      </div>
                    </>
                  )
                  :
                  (
                    <div className="apd-card__status">
                      <span><Svg icon={SVG_ICONS.NAVIGATION_ARROW} size={18} /></span>
                      <span>Sending...</span>
                    </div>
                  )
            }
            
          </Form>
        </div>
      </div>
    </div>
  );
};
