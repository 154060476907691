import React from "react";
import { Link } from "react-router-dom";
import { FormCard } from "../../../components/_common/FormCard/FormCard";
import { ROUTES } from "../../../configs/routes";
import whiteLogo from "../../../assets/images/png/mfp-logo-white.png";
import { SignInForm } from "../../../components/authorization/SignInForm/SignInForm";
import { SignInEntity } from "../../../types/authorization";
import { useUserLogin } from "../../../hooks/auth-hooks";

import "./SignInPage.scss";

export const SignInPage: React.FC = () => {
  const { login } = useUserLogin();

  const handleLogin = (data: SignInEntity) => {
    login(data);
  };

  return (
    <div className="signin">
      <div className="signin__content">
        <div className="signin__logo">
          <img src={whiteLogo} alt="" />
        </div>
        <FormCard title="Sign In">
          <SignInForm onSubmit={handleLogin} />
        </FormCard>
      </div>
    </div>
  );
};
