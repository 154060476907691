import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import Datetime from 'react-datetime';
import classNames from 'classnames';

import './Datepicker.scss';

interface Props {
  value: Date;
  title?: string;
  name?: string;
  placeholder?: string;
  error?: string;
  onChange(value: Date | string, name: string): void;
}

export const Datepicker: React.FC<Props> = (props) => {
  const {value, title, name, error, placeholder, onChange} = props;
  const [inputValue, setInputValue] = useState('');
  const wrapperClass = classNames('datepicker', {
    'datepicker--error': !!error,
  });

  useEffect(() => {
    if (value) {
      setInputValue(moment(value).utc().format('DD/MM/YYYY'));
    } else {
      setInputValue('');
    }
  }, [value]);

  const handleChange = (momentVal: Moment | string) => {
    if (typeof momentVal === 'string') {
      onChange(undefined, name);
      setInputValue('');
    } else {
      onChange(momentVal.toDate(), name);
    }
  };

  const isValidDate = (current, selected) => {
    return moment(current).isBefore(moment(selected));
  };

  return (
    <div className={wrapperClass}>
      {title && <div className="datepicker__title">{title}</div>}
      <Datetime
        value={value}
        utc
        initialViewMode="years"
        timeFormat={false}
        inputProps={{value: inputValue, placeholder}}
        isValidDate={isValidDate}
        onChange={handleChange}
      />
      {error && <div className="datepicker__error">{error}</div>}
    </div>
  );
};
