import React from 'react';
import { useSelector } from 'react-redux';
import { UserMenu } from '../UserMenu/UserMenu';
import { UtilsUser } from '../../utils/user';
import { UserState } from '../../store/reducers/user-reducer';
import { ApplicationState } from '../../store';
import logo from '../../assets/images/png/logo-coloured.png'

import './Header.scss';

interface Props {
  isProfile?: boolean;
}

export const Header: React.FC<Props> = (props) => {
  const {isProfile} = props;
  const userState = useSelector<ApplicationState, UserState>(state => state.user);
  const {userData} = userState;
  const {registration_status} = userData;
  const isPaymentFinished = UtilsUser.isUserPaymentCompleted(registration_status);
  const showUserMenu = isPaymentFinished && isProfile;

  return (
    <header className="header">
      <div className="container">
        <div className="header__content">
          <div className="header__logo">
            <img src={logo} alt="" />
          </div>
          {showUserMenu && <UserMenu />}
        </div>
      </div>
    </header>
  );
};
