import React, { useState } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { Form } from '../../_common/Form/Form';
import { paymentDefaults } from '../../../data/defaults';
import { Button } from '../../_common/_controls/Button/Button';
import { PaymentEntity } from '../../../types/payment';
import { Icon } from '../../_common/Icon/Icon';
import { ApplicationState } from '../../../store';
import { CommonState } from '../../../store/reducers/common-reducer';

import './PaymentForm.scss';

interface Props {
  onSubmit(data: PaymentEntity): void;
}

const CARD_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      iconColor: '#121219',
      color: '#121219',
      fontWeight: '500',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '18px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {color: '#858589'},
      '::placeholder': {color: '#858589'},
    },
    invalid: {
      iconColor: 'red',
      color: 'red',
    },
  },
};

export const PaymentForm: React.FC<Props> = (props) => {
  const {onSubmit} = props;
  const commonState = useSelector<ApplicationState, CommonState>(state => state.common);
  const {voucherIsApplied} = commonState;
  const [formData] = useState<PaymentEntity>(paymentDefaults);

  const handleFromSubmit = () => {
    onSubmit(formData);
  };

  return (
    <Form className="payment-form" onSubmit={handleFromSubmit}>
      {/*{!voucherIsApplied && (*/}
      {/*  <div className="payment-form__stripe-wrap">*/}
      {/*    <CardElement options={CARD_OPTIONS} />*/}
      {/*  </div>*/}
      {/*)}*/}
      <div className="payment-form__fields">
        <Button title="Complete Purchase" htmlType="submit" />
      </div>
      {/*<div className="payment-form__guarantee">*/}
      {/*  <div className="payment-form__guarantee-icons">*/}
      {/*    <Icon name="visa" width={46} height={36} />*/}
      {/*    <Icon name="maestro" width={46} height={36} />*/}
      {/*    <Icon name="jcb" width={46} height={36} />*/}
      {/*  </div>*/}
      {/*  <div className="payment-form__guarantee-notice">*/}
      {/*    <Icon name="lock" width={16} height={20} /> Secured payment*/}
      {/*  </div>*/}
      {/*</div>*/}
    </Form>
  );
};
