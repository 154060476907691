import React, { forwardRef, Ref } from 'react';
import Select from 'react-select';
import classNames from 'classnames';

import './SelectMenu.scss';

interface Props {
  value: string;
  options: any[];
  title?: string;
  name?: string;
  error?: string;
  labelKey?: string;
  valueKey?: string;
  placeholder?: string;
  disabled?: boolean;
  ref?: Ref<any>;
  onChange(value: string, name: string): void;
}

export const SelectMenu: React.FC<Props> = (props) => {
  const { title, value, error, name, placeholder, options, disabled, labelKey = 'label', valueKey = 'value', onChange } = props;
  const selectedOption = options.find((option) => value === option[valueKey]) || '';
  const wrapperClass = classNames('select-menu', {
    'select-menu--error': !!error,
  });
  const dropdownIndicatorStyles = (base: any, state: any) => {
    let sizes = {
      width: '36px',
      height: '36px',
    };
    return Object.assign(base, sizes);
  };

  const handleChange = (option: any) => {
    onChange(option[valueKey], name);
  };

  return (
    <div className={wrapperClass}>
      {title && <div className="select-menu__title">{title}</div>}
      <Select
        className="select-menu__field"
        classNamePrefix="select-menu"
        styles={{ dropdownIndicator: dropdownIndicatorStyles }}
        value={selectedOption}
        placeholder={placeholder}
        onChange={handleChange}
        options={options}
        isDisabled={disabled}
        getOptionLabel={(option) => option[labelKey]}
        getOptionValue={(option) => option[valueKey]}
      />
      {error && <div className="select-menu__error">{error}</div>}
    </div>
  );
};
