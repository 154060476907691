import React from 'react';

import './ProfileInfoItem.scss';

interface Props {
  name: string;
  value: string;
  note?: string;
}

export const ProfileInfoItem: React.FC<Props> = (props) => {
  const {value, name, note} = props;

  return (
    <div className="pi-item">
      <div className="pi-item__name">{name}</div>
      <div className="pi-item__value">
        {value}
        <span className="pi-item__note">{note}</span>
      </div>
    </div>
  );
};
