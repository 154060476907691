import React, { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Form } from '../../_common/Form/Form';
import { FormGroup } from '../../_common/Form/FormGroup/FormGroup';
import { GuardianEntity } from '../../../types/authorization';
import { guardianDefaults } from '../../../data/defaults';
import { Input } from '../../_common/_controls/Input/Input';
import { Button } from '../../_common/_controls/Button/Button';
import { Checkbox } from '../../_common/_controls/Checkbox/Checkbox';
import { Datepicker } from '../../_common/_controls/Datepicker/Datepicker';
import { SelectMenu } from '../../_common/_controls/SelectMenu/SelectMenu';
import { VALIDATION_MESSAGES } from '../../../constants/validation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CountriesCodes from '../../../data/json/countries-phones.json';
import moment from 'moment';

interface Props {
  isLoading: boolean;
  onSubmit(data: GuardianEntity): void;
}

const validationSchema = yup.object().shape({
  name: yup.string().required(VALIDATION_MESSAGES.REQUIRED),
});

export const GuardianForm: React.FC<Props> = (props) => {
  const {onSubmit, isLoading} = props;
  const { push } = useHistory();
  const {register, handleSubmit, errors, clearErrors} = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onSubmit',
  });
  const [formData, setFormData] = useState<GuardianEntity>(guardianDefaults);
  const {name, dob, address1, address2, city, country, zipCode, rememberInfo} = formData;

  const handleFieldChange = (value: string | boolean | Date, fieldName: string) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });

    clearErrors(fieldName);
  };

  const handleFromSubmit = (e: ChangeEvent<any>) => {
    const yearsDiff = moment().diff(moment(dob), 'years');
    const userIsUnder16 = yearsDiff < 16;

    if (userIsUnder16) {
      alert('The person must be over 16 years old');
      return;
    }

    onSubmit(formData);
  };

  return (
    <Form className="signup-form" onSubmit={handleSubmit(handleFromSubmit)}>
      <FormGroup title="GENERAL INFORMATION">
        <Input
          title="First and Last Name"
          value={name}
          name="name"
          ref={register}
          placeholder="Enter your first and last name"
          error={errors.name && errors.name.message}
          onChange={handleFieldChange}
        />
        <Datepicker
          title="Date of Birth"
          value={dob}
          name="dob"
          onChange={handleFieldChange}
        />
      </FormGroup>
      <FormGroup title="HOME ADDRESS">
        <Input
          title="Address Line 1"
          value={address1}
          name="address1"
          placeholder="Number, street name"
          onChange={handleFieldChange}
        />
        <Input
          title="Address Line 2"
          value={address2}
          name="address2"
          placeholder="Apartment, Suite, Unit, etc."
          onChange={handleFieldChange}
        />
        <Input
          title="Town/City"
          value={city}
          name="city"
          placeholder="Town/City"
          onChange={handleFieldChange}
        />
        <SelectMenu
          title="Country"
          value={country}
          name="country"
          options={CountriesCodes}
          labelKey="name"
          valueKey="name"
          placeholder="Select Country"
          onChange={handleFieldChange}
        />
        <Input
          title="Postcode"
          value={zipCode}
          name="zipCode"
          placeholder="Example: L1 8JQ"
          onChange={handleFieldChange}
        />
      </FormGroup>
      <div className="signup-form__extra">
        <Checkbox
          checked={rememberInfo}
          name="rememberInfo"
          onChange={handleFieldChange}
          text="Remember Guardian’s information"
        />
      </div>
      <div className="signup-form__actions">
        <Button title="Consent for Payment" disabled={isLoading} htmlType="submit" />
      </div>
    </Form>
  );
};
