export const USER_ACTION_TYPES = {
  SET_USER: 'SET_USER',
  SET_USER_QR_CODE: 'SET_USER_QR_CODE',
  SET_USER_PROFILE_PHOTO: 'SET_USER_PROFILE_PHOTO',
  SET_USER_ID_PHOTO: 'SET_USER_ID_PHOTO',
  RESET_USER: 'RESET_USER',
};

export const COMMON_ACTION_TYPES = {
  SET_VOUCHER_CODE: 'SET_VOUCHER_CODE',
  SET_APP_LINK_SENT: 'SET_APP_LINK_SENT'
};
