import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form } from '../../_common/Form/Form';
import { signInDefaults } from '../../../data/defaults';
import { SignInEntity } from '../../../types/authorization';
import { Input } from '../../_common/_controls/Input/Input';
import { Button } from '../../_common/_controls/Button/Button';

import './SignInForm.scss';
import { ROUTES } from '../../../configs/routes';

interface Props {
  onSubmit(data: SignInEntity);
}

export const SignInForm: React.FC<Props> = (props) => {
  const {onSubmit} = props;
  const [formData, setFormData] = useState<SignInEntity>(signInDefaults);
  const {password, login} = formData;
  const formIsDisabled = !password || !login;

  const handleFieldChange = (value: string, name: string) => {
    setFormData({...formData, [name]: value});
  };

  const handleFormSubmit = () => {
    onSubmit(formData);
  };

  return (
    <Form className="signin-form" onSubmit={handleFormSubmit}>
      <Input
        title="Email / Phone Number"
        placeholder="Enter your email or phone number"
        value={login}
        name="login"
        onChange={handleFieldChange}
      />
      <Input
        title="Password"
        value={password}
        name="password"
        placeholder="Enter your password"
        password
        onChange={handleFieldChange}
      />
      <div className="signin-form__forgot">
        <Link to={ROUTES.FORGOT_PASSWORD}>Forgot your password?</Link>
      </div>
      <Button disabled={formIsDisabled} title="Sign In" htmlType="submit" />
    </Form>
  );
};
