import React from 'react';

import './FormGroup.scss';

interface Props {
  title?: string;
}

export const FormGroup: React.FC<Props> = (props) => {
  const {title, children} = props;

  return (
    <div className="form-group">
      {title && <div className="form-group__title">{title}</div>}
      <div className="form-group__fields">
        {children}
      </div>
    </div>
  );
};
