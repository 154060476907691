import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import { API_ENDPOINTS, API_URL } from '../configs/api';
import { Http } from '../services/http';
import { ROUTES } from '../configs/routes';
import { actionSetVoucherState } from '../store/actions/common-actions';
import { ApplicationState } from '../store';
import { CommonState } from '../store/reducers/common-reducer';
import { actionSetUserProfile } from '../store/actions/user-actions';
import { useUserSession } from './auth-hooks';

export function usePayment() {
  const {push} = useHistory();
  const commonState = useSelector<ApplicationState, CommonState>(state => state.common);
  const [isLoading, setIsLoading] = useState(false);
  const {voucherIsApplied} = commonState;
  const stripe = useStripe();
  const elements = useElements();

  const sendPayment = async () => {
    try {
      setIsLoading(true);
      if (voucherIsApplied) {
        push(ROUTES.PAYMENT_COMPLETE);
        return;
      } else {
        alert('Voucher is not applied!')
      }

      // if (!stripe || !elements) {
      //   return;
      // }
      //
      // const cardElement = elements.getElement(CardElement);
      // const tokenRes = await stripe.createToken(cardElement);
      // const {token, error} = tokenRes;
      //
      // if (error) {
      //   alert(error.message);
      //   return;
      // }
      //
      // const {id} = token;
      // const paymentUrl = `${API_URL}/${API_ENDPOINTS.PAYMENT}`;
      //
      // await Http.post(paymentUrl, {chargeStripeId: id});
      //
      // push(ROUTES.PAYMENT_COMPLETE);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      alert(e.message);
    }
  };

  return {sendPayment};
}

export function useVoucherCode() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const {saveRegistrationStatus} = useUserSession();

  const sendCode = async (code: string) => {
    try {
      setIsLoading(true);
      const requestData = {code};
      const url = `${API_URL}/${API_ENDPOINTS.VOUCHER_CODE}`;

      const res = await Http.post(url, requestData);

      setIsLoading(false);
      saveRegistrationStatus(res);
      dispatch(actionSetUserProfile(res));
      dispatch(actionSetVoucherState(true));
      alert('Success your voucher has been applied');
    } catch (e) {
      setIsLoading(false);
      console.log(e.message);
      alert(e.message);
      // alert('Error - that is an incorrect error Code');
    }
  };

  return {sendCode, isLoading};
}
