import React, { ChangeEvent } from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
  onSubmit(): void;
}

export const Form: React.FC<Props> = (props) => {
  const {children, className, onSubmit} = props;
  const formClass = classNames('form', {
    [className]: !!className,
  });

  const handleSubmit = (e: ChangeEvent<any>) => {
    e.preventDefault();

    onSubmit();
  };

  return (
    <form className={formClass} onSubmit={handleSubmit}>
      {children}
    </form>
  );
};
