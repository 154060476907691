import React from 'react';
import { ReactSVG } from 'react-svg';
import Logout from '../../../assets/images/svg/logout.svg';
import Ticket from '../../../assets/images/svg/ticket.svg';
import Invoice from '../../../assets/images/svg/invoice.svg';
import UserCircle from '../../../assets/images/svg/male-avatar-circle.svg';
import User from '../../../assets/images/svg/user.svg';
import ArrowHeadUp from '../../../assets/images/svg/arrowhead-up.svg';
import ZoomIn from '../../../assets/images/svg/zoom-in.svg';
import NavigationArrow from '../../../assets/images/svg/solid-navigation-arrow.svg';
import CheckCircleGreen from '../../../assets/images/svg/check-circle.svg';

import './Svg.scss';

interface Props {
  icon: string;
  size: number;
}

export const SVG_ICONS = {
  LOGOUT: Logout,
  TICKET: Ticket,
  INVOICE: Invoice,
  USER_CIRCLE: UserCircle,
  USER: User,
  ARROW_HEAD_UP: ArrowHeadUp,
  ZOOM_IN: ZoomIn,
  NAVIGATION_ARROW: NavigationArrow,
  CHECK_CIRCLE_GREEN: CheckCircleGreen
};

export const Svg: React.FC<Props> = (props) => {
  const {icon, size} = props;
  const style = {width: size, height: size};

  return (
    <ReactSVG style={style} className="svg-icon" src={icon} wrapper="span" />
  );
};
