export enum RegistrationStatus {
  PHONE_VERIFICATION = 'PHONE_VERIFICATION',
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
  REQUEST_BASE_ACCOUNT_DATA = 'REQUEST_BASE_ACCOUNT_DATA',
  REQUEST_ADDRESS = 'REQUEST_ADDRESS',
  REQUEST_TERMS_AND_CONDITION_AGREEMENT = 'REQUEST_TERMS_AND_CONDITION_AGREEMENT',
  REQUEST_PROFILE_PICTURE = 'REQUEST_PROFILE_PICTURE',
  REQUEST_ID_VERIFICATION = 'REQUEST_ID_VERIFICATION',
  REQUEST_DATA_SHARING_AGREEMENT = 'REQUEST_DATA_SHARING_AGREEMENT',
  REGISTRATION_COMPLETED = 'REGISTRATION_COMPLETED',
  PENDING_ID_VERIFICATION = 'PENDING_ID_VERIFICATION',
  SELFIE_VERIFICATION = 'SELFIE_VERIFICATION',
  ID_READ_FAILED = 'ID_READ_FAILED',
  FACE_READ_FAILED = 'FACE_READ_FAILED',
  VERIFICATION_PASSED = 'VERIFICATION_PASSED',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED ',
  FACEMATCH_FAILED = 'FACEMATCH_FAILED',
  BIRTHDAY_MATCH_FAILED = 'BIRTHDAY_MATCH_FAILED',
  ADDRESS_MATCH_FAILED = 'ADDRESS_MATCH_FAILED',
  NAME_MATCH_FAILED = 'NAME_MATCH_FAILED',
}
