export const API_URL = process.env.REACT_APP_API_URL;
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUPBLIC_KEY;
// export const API_URL = 'http://api.user.matchfit.ucoders.club/user';
// export const API_URL = 'http://api.user.matchfitpass.com/user';

export const API_ENDPOINTS = {
  SEND_PHONE_CODE: 'auth/signup',
  VERIFY_PHONE_CODE: 'users/me/phone/verify',
  VERIFY_PHONE_CODE_RESEND: 'users/me/phone/resend',
  SEND_EMAIL_CODE: 'users/me/email',
  SEND_EMAIL_CODE_RESEND: 'users/me/email/resend',
  VERIFY_EMAIL_CODE: 'users/email/verify',
  USER_ACCOUNT_INFO: 'users/me/address',
  USER_ACCOUNT_BASE: 'users/me/base-account-data',
  GUARDIAN_INFO: 'users/me/add-guardians-info',
  PAYMENT: 'users/me/payment',
  VOUCHER_CODE: 'users/me/use-code',
  PAYMENT_TEST: 'stripe/test',
  USER_DATA: 'auth/me',
  USER_QR: 'users/me/qr',
  USER_PROFILE_PHOTO: 'users/me/selfie',
  USER_ID_PHOTO: 'users/me/id-photo',
  LOGIN: 'auth/signin',
  FORGOT_PASSWORD: 'users/forgot-password',
  RESET_PASSWORD: 'users/reset-password',
};
