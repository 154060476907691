import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ProfileHeader } from '../../../components/profile/ProfileHeader/ProfileHeader';
import { ProfileCard } from '../../../components/profile/ProfileCard/ProfileCard';
import { ProfileEditableField } from '../../../components/profile/ProfileEditableField/ProfileEditableField';
import { ProfileInfoItem } from '../../../components/profile/ProfileInfoItem/ProfileInfoItem';
import { ProfilePhotoInfo } from '../../../components/profile/ProfilePhotoInfo/ProfilePhotoInfo';
import { ApplicationState } from '../../../store';
import { UserState } from '../../../store/reducers/user-reducer';
import { useUserDataLoad } from '../../../hooks/user-hooks';
import { UtilsCommon } from '../../../utils/common';
import countries from '../../../data/json/countries-phones.json';

import './ProfilePage.scss';

export const ProfilePage: React.FC = () => {
  const {loadQr, loadProfilePhoto, loadIdPhoto} = useUserDataLoad();
  const userState = useSelector<ApplicationState, UserState>(state => state.user);
  const {userData, qrCode, profilePhoto, idPhoto} = userState;
  const {phone, email, name, dob, address1, address2, verified_at, country, city, postcode} = userData;
  const profilePhotoVerDate = moment(verified_at || undefined).format('DD MMMM, YYYY');
  const idPhotoVerDate = moment(verified_at || undefined).format('DD MMMM, YYYY');
  const nextPpUpdateDate = moment(profilePhotoVerDate).add(1, 'y').format('DD MMMM, YYYY');
  const foundCountry = countries.find((item) => item.code === country.toUpperCase());
  const displayedCountry = foundCountry ? foundCountry.name : country.toUpperCase();

  useEffect(() => {
    loadQr();
    loadProfilePhoto();
    loadIdPhoto();
  }, []);

  const handleUpdateRequest = () => {

  };
  const handleAccountClose = () => {

  };

  return (
    <div className="profile-info">
      <ProfileHeader title="Pass & Profile" />
      <div className="profile-info__content">
        <div className="profile-info__left">
          <ProfileCard title="Secure QR Code">
            <ProfilePhotoInfo imageUrl={UtilsCommon.getImageUrlFromBase64(qrCode.image)} placeholder="No QR Code" />
          </ProfileCard>
          <ProfileCard title="Profile Photo">
            <ProfilePhotoInfo
              imageUrl={UtilsCommon.getImageUrlFromBase64(profilePhoto.image)}
              status={`Verified on ${profilePhotoVerDate}`}
              verified
              notice={`Required to be updated every year. Next update: ${nextPpUpdateDate}`}
              placeholder="No Profile Photo"
            />
          </ProfileCard>
          <ProfileCard title="ID Document">
            <ProfilePhotoInfo
              imageUrl={UtilsCommon.getImageUrlFromBase64(idPhoto.image)}
              status={`Verified on ${idPhotoVerDate}`}
              verified
              notice="Driving Licence. Required to be updated when the actual document is changed."
              placeholder="No ID Document"
            />
          </ProfileCard>
        </div>
        <div className="profile-info__right">
          <ProfileCard title="Match Fit Pass Number">
            <div className="profile-info__code">{userData.id}</div>
          </ProfileCard>
          <ProfileCard title="Phone Number">
            <ProfileEditableField value={phone} />
          </ProfileCard>
          <ProfileCard title="Email">
            <ProfileEditableField value={email} />
          </ProfileCard>
          <ProfileCard title="Personal Information">
            <ProfileInfoItem name="Full Name" value={name} />
            <ProfileInfoItem name="DOB" value={dob} />
            <ProfileInfoItem
              name="Address"
              value={`${address1} ${address2}, ${city}, ${displayedCountry}, ${postcode}`}
            />
          </ProfileCard>
        </div>
      </div>
      <div className="profile-info__footer">
        {/*<div className="profile-info__actions">*/}
        {/*  <Button*/}
        {/*    className="profile-info__update-btn"*/}
        {/*    title="Request Pass & Profile Update"*/}
        {/*    type="secondary"*/}
        {/*    onClick={handleUpdateRequest} />*/}
        {/*</div>*/}
        {/*<div className="profile-info__management">*/}
        {/*  <div className="profile-info__management-title">Account Management</div>*/}
        {/*  <div className="profile-info__management-content">*/}
        {/*    <div className="profile-info__management-text">*/}
        {/*      Permanently close your account. We won’t keep any data of your profile, <br />logs and activities.*/}
        {/*    </div>*/}
        {/*    <Button title="Close Your Account" onClick={handleAccountClose} type="danger" />*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};
