import { LocalStorage } from '../services/storage';
import { USER_LOGIN_STATUS_NAMESPACE, USER_REGISTRATION_STATUS_NAMESPACE } from '../constants/namespaces';
import { RegistrationStatus } from '../constants/verification';

export class UtilsUser {
  public static isUserLoggedIn(): boolean {
    return !!LocalStorage.get(USER_LOGIN_STATUS_NAMESPACE);
  }

  public static isUserOnboardingComplete(): boolean {
    return this.isUserPaymentCompleted(LocalStorage.get(USER_REGISTRATION_STATUS_NAMESPACE));
  }

  public static isUserPaymentCompleted(status): boolean {
    if (!status || status === '') {
      return true;
    }
    switch (status) {
      case RegistrationStatus.REQUEST_PROFILE_PICTURE:
      case RegistrationStatus.REQUEST_ID_VERIFICATION:
      case RegistrationStatus.REQUEST_DATA_SHARING_AGREEMENT:
      case RegistrationStatus.REGISTRATION_COMPLETED:
        return true;
      default:
        return false;
    }
  };
}