import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CompletionCard } from '../../../components/_common/CompletionCard/CompletionCard';
import { AppDownloadCard } from '../../../components/payment/AppDownloadCard/AppDownloadCard';
import { ApplicationState } from '../../../store';
import { UserState } from '../../../store/reducers/user-reducer';
import { ROUTES } from '../../../configs/routes';
import { useSms } from '../../../hooks/common-hooks';

export const PaymentCompletePage: React.FC = () => {
  const { sendSmsAppLinks, isLoading } = useSms();
  const userState = useSelector<ApplicationState, UserState>((state) => state.user);
  const {push} = useHistory();
  const { userData } = userState;
  const { email, phone } = userData;

  const handleProfileView = () => {
    push(ROUTES.PROFILE_INFO);
  };

  const handleSendAppLink = async () => {
    await sendSmsAppLinks(phone);
  }

  return (
    <>
      <CompletionCard
        title="Welcome aboard!"
        actionText="View my account"
        onAction={handleProfileView}
        text={(
          <>
            <p>You can download the Match Fit Pass mobile app and proceed to verify ID now.</p>
            <p>Details of your purchase has been sent to your email <br /> <b>{email}.</b></p>
          </>
        )}
      />
      <AppDownloadCard
        phone={phone}
        onSubmit={handleSendAppLink}
        isLoading={isLoading} />
    </>
  );
};
