import React from 'react';

import './CompletionCard.scss';

interface Props {
  title: string;
  text: React.ReactNode;
  actionText?: string;
  onAction?(): void;
}

export const CompletionCard: React.FC<Props> = (props) => {
  const {title, text, actionText, onAction} = props;

  const handleActionClick = () => {
    onAction && onAction();
  };

  return (
    <div className="completion-card">
      <div className="completion-card__title">{title}</div>
      <div className="completion-card__text">{text}</div>
      {actionText && (
        <div className="completion-card__actions">
          <button className="completion-card__btn" type="button" onClick={handleActionClick}>{actionText}</button>
        </div>
      )}
    </div>
  );
};
