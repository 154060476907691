import React, { RefObject, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useOnClickOutside } from '../../hooks/common-hooks';
import { ROUTES } from '../../configs/routes';
import { Svg, SVG_ICONS } from '../_common/Svg/Svg';
import { useUserLogout } from '../../hooks/auth-hooks';

import './UserMenu.scss';

export const UserMenu: React.FC = () => {
  const {logout} = useUserLogout();
  const menuRef = useRef() as RefObject<any>;
  const [menuIsActive, setMenuIsActive] = useState(false);

  const btnClass = classNames('user-menu__btn', {
    'user-menu__btn--active': menuIsActive,
  });

  const handleToggleMenu = () => {
    setMenuIsActive(!menuIsActive);
  };

  const closeMenu = () => {
    setMenuIsActive(false)
  };

  const handleLogout = () => {
    closeMenu();
    logout();
  };

  useOnClickOutside(menuRef, closeMenu);

  return (
    <div className="user-menu" ref={menuRef}>
      <button className={btnClass} onClick={handleToggleMenu} type="button">
        <Svg icon={SVG_ICONS.USER} size={16} />
        <span className="user-menu__btn-text">My Account</span>
        <span className="user-menu__btn-arrow">
          <Svg icon={SVG_ICONS.ARROW_HEAD_UP} size={12} />
        </span>
      </button>
      {menuIsActive && (
        <ul className="user-menu__list">
          <li>
            <Link to={ROUTES.PROFILE_INFO} onClick={handleToggleMenu}>
              <Svg icon={SVG_ICONS.USER_CIRCLE} size={16} /> Account & Settings
            </Link>
          </li>
          {/*<li>*/}
          {/*  <Link to={ROUTES.ROOT} onClick={handleToggleMenu}>*/}
          {/*    <Svg icon={SVG_ICONS.TICKET} size={16} /> My Tickets*/}
          {/*  </Link>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <Link to={ROUTES.ROOT} onClick={handleToggleMenu}>*/}
          {/*    <Svg icon={SVG_ICONS.INVOICE} size={16} /> Billing*/}
          {/*  </Link>*/}
          {/*</li>*/}
          <li>
            <button type="button" onClick={handleLogout}>
              <Svg icon={SVG_ICONS.LOGOUT} size={16} /> Sign Out
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};
