import React from 'react';
import { useSelector } from 'react-redux';
import { FormCard } from '../../../components/_common/FormCard/FormCard';
import { CompletionCard } from '../../../components/_common/CompletionCard/CompletionCard';
import { VoucherField } from '../../../components/payment/VoucherField/VoucherField';
import { PaymentForm } from '../../../components/payment/PaymentForm/PaymentForm';
import { usePayment } from '../../../hooks/payment-hooks';
import { ApplicationState } from '../../../store';
import { CommonState } from '../../../store/reducers/common-reducer';

import './PaymentPage.scss';

export const PaymentPage: React.FC = () => {
  const {sendPayment} = usePayment();
  const commonState = useSelector<ApplicationState, CommonState>(state => state.common);
  const {voucherIsApplied} = commonState;

  const handleFormSubmit = () => {
    sendPayment();
  };

  return (
    <>
      <CompletionCard
        title="Thank your for signing up!"
        text="You can continue with the payment process for subscription now."
      />
      <FormCard title="Payment" className="payment-card">
        <VoucherField />
        <div className="payment-card__divider" />
        {/*{!voucherIsApplied && (*/}
        {/*  <div className="payment-card__title">Pay securely with your credit card via Stripe.</div>*/}
        {/*)}*/}
        <PaymentForm onSubmit={handleFormSubmit} />
      </FormCard>
    </>
  );
};
